// @ts-strict-ignore
import { LoginStatus } from 'constants/login-status';
import { TopBarNotificationType } from 'constants/notifications';
import { AppStateProvider } from 'services/app-state-provider';
import { getDesktopApp } from 'services/desktop-application/desktop-app';
import { tryToLogUserSession } from 'services/socket-activity-logger';
import { mapRoutingStatusToLoginStatus } from 'services/socket-lc3/agent/helpers';
import { handleLogin as handleLoginForChats } from 'services/socket-lc3/chat/event-handling/login';
import type { ILoginResponsePushEvent } from 'services/web-socket/interfaces';
import { AgentActions } from 'store/entities/agents/actions';
import { getLoggedInAgent } from 'store/entities/agents/selectors';
import { ChatsEntitiesActions } from 'store/entities/chats/actions';
import { NotificationsBarActions } from 'store/features/notifications-bar/actions';

export function handleLoginSuccessful(payload: ILoginResponsePushEvent): void {
  handleLoginForChats(payload);

  const loggedAgent = AppStateProvider.selectFromStore(getLoggedInAgent);

  getDesktopApp().agentLoggedIn({
    login: loggedAgent.email,
    appUrl: `${window.location.origin}/`,
  });

  payload.chats_summary.forEach((chat) => {
    if (chat?.users) {
      AppStateProvider.dispatch(ChatsEntitiesActions.addChatUsers({ chatId: chat.id, chatUsers: chat.users }));
    }
  });

  tryToLogUserSession();

  const status = mapRoutingStatusToLoginStatus(payload.my_profile.routing_status);

  AppStateProvider.dispatch(
    AgentActions.agentUpdate({
      login: loggedAgent.login,
      status,
    }),
  );

  if (status === LoginStatus.Away) {
    AppStateProvider.dispatch(
      NotificationsBarActions.showNotificationsBar({ name: TopBarNotificationType.StatusAway }),
    );
  }
}
