import { createSelector } from 'reselect';

// eslint-disable-next-line import/no-restricted-paths
import { getCopy, getDaysUntilMigration } from 'components/global-modals/context-event/helpers';
import { PlanType } from 'constants/plan-type';
import { QueryKey } from 'constants/query-key';
import { PlatformNamespace, BillingProperty } from 'services/connectivity/configuration-api/properties/constants';
import { getQueryClient } from 'services/query-client/client';
import { QUERY_KEYS } from 'services/query-client/keys';
import { getSubscriptionPlan } from 'store/entities/subscription/selectors';

import { getContextModalSeen } from '../agent-custom-properties/selectors';
import { getCanManageSubscription } from '../session/selectors';

export const getShouldShowContextModal = createSelector(
  [getCanManageSubscription, getContextModalSeen, getSubscriptionPlan],
  (canManageSubscription, contextModalSeen, subscriptionPlan) => {
    const daysUntilMigration = getDaysUntilMigration();

    if (daysUntilMigration === 0) {
      return false;
    }

    const modalHasContent = getCopy(daysUntilMigration);
    const isBusinessPlan = subscriptionPlan === PlanType.Business;
    const isEnterprisePlan = subscriptionPlan === PlanType.EnterprisePlus;

    const higherPlanTrialAccepted = !!getQueryClient().getQueryData(
      QUERY_KEYS[QueryKey.LicenseProperties](PlatformNamespace.BILLING),
    )?.[BillingProperty.HigherPlanTrialAccepted];

    const higherPlanTrialStarted = !!getQueryClient().getQueryData(
      QUERY_KEYS[QueryKey.LicenseProperties](PlatformNamespace.BILLING),
    )?.[BillingProperty.HigherPlanTrialStarted];

    return (
      higherPlanTrialStarted &&
      !!modalHasContent &&
      !higherPlanTrialAccepted &&
      canManageSubscription &&
      !contextModalSeen &&
      !isBusinessPlan &&
      !isEnterprisePlan
    );
  },
);
