// @ts-strict-ignore
import { urlDomainExtractor } from 'helpers/regexp';
import { isEmail } from 'helpers/validate';
import { type IProductOnboardingProgress, ProductOnboardingStep } from 'interfaces/entities/product-onboarding';

import { type IOnboardingProgressDTO } from './interfaces';

export const EMPTY_URL_ERROR = 'Empty URL';
const INVALID_URL_ERROR = 'Invalid url provided';

export function extractDomainOnly(website: string): string {
  const matchArray: RegExpMatchArray = website.match(urlDomainExtractor);

  return matchArray && matchArray[2];
}

export function extractDomain(website: string): Promise<string> {
  return new Promise((resolve, reject) => {
    if (!website) {
      reject(new Error(EMPTY_URL_ERROR));
    }

    if (isEmail(website)) {
      resolve(website.substr(website.indexOf('@') + 1));
    } else {
      const domainOnly: string = extractDomainOnly(website);
      if (domainOnly) {
        resolve(domainOnly);
      } else {
        reject(new Error(INVALID_URL_ERROR));
      }
    }
  });
}

export const deserializeOnboardingProgress = (
  onboardingProgress: IOnboardingProgressDTO,
): IProductOnboardingProgress => ({
  accountActivated: onboardingProgress.account_activated?.completed,
  avatarUploaded: onboardingProgress.avatar_uploaded?.completed,
  codeInstalled: onboardingProgress.code_installed?.completed,
  desktopAppDownloaded: onboardingProgress.desktop_app_downloaded?.completed,
  sampleChat: onboardingProgress.sample_chat?.completed,
  inviteTeammates: onboardingProgress.invite_teammates?.completed,
  installMessenger: onboardingProgress.install_messenger?.completed,
  installWhatsApp: onboardingProgress.install_whatsapp?.completed,
  installTwilio: onboardingProgress.install_twilio?.completed,
  installTelegram: onboardingProgress.install_telegram?.completed,
});

export const serializeOnboardingStep = (step: ProductOnboardingStep): string => {
  switch (step) {
    case ProductOnboardingStep.AvatarUploaded:
      return 'avatar_uploaded';
    case ProductOnboardingStep.CodeInstalled:
      return 'code_installed';
    case ProductOnboardingStep.DesktopAppDownloaded:
      return 'desktop_app_downloaded';
    case ProductOnboardingStep.SampleChat:
      return 'sample_chat';
    case ProductOnboardingStep.InviteTeammates:
      return 'invite_teammates';
    case ProductOnboardingStep.InstallMessenger:
      return 'install_messenger';
    case ProductOnboardingStep.InstallWhatsApp:
      return 'install_whatsapp';
    case ProductOnboardingStep.InstallTwilio:
      return 'install_twilio';
    case ProductOnboardingStep.InstallTelegram:
      return 'install_telegram';
  }
};
