import debug from 'debug';

import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { getServer } from 'helpers/get-server';
import { type IServer } from 'interfaces/server';
import { tryToLogUserSession } from 'services/socket-activity-logger';

import { type IServerConnectOptions } from './interfaces';

const log = debug(DebugLogsNamespace.AppServerConnection);

function connectToServer(server: IServer, options: IServerConnectOptions): Promise<void> {
  return new Promise((resolve, reject) => {
    const { isGhost, accessToken } = options;

    const connectHandler = (): void => {
      log('Connected to server');
      server.off('connect', connectHandler);
      resolve();
    };

    if (isGhost) {
      log('Not connecting to platform client (ghost mode)');
      resolve();

      return;
    }

    server.on('connect', connectHandler);

    try {
      log('Connecting to platform client');
      server.connect(accessToken);
    } catch (error) {
      reject(error);
    }
  });
}

export async function connectAndLogin(options: IServerConnectOptions): Promise<void> {
  const { isGhost } = options;
  const server: IServer = getServer();

  log('Connecting to server');
  await connectToServer(server, options);

  if (isGhost) {
    log('Logging in as ghost');
    tryToLogUserSession();

    return;
  }
}
