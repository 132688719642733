// @ts-strict-ignore
import { differenceInCalendarDays, isAfter, isBefore } from 'date-fns';

// eslint-disable-next-line import/no-restricted-paths
import { getDaysUntilMigration } from 'components/global-modals/context-event/helpers';
import { AUTOMATE_SECTION_RELEASE_DATE } from 'constants/automate/constants';
import { CUSTOMER_DETAILS_DEFAULT_SECTIONS } from 'constants/customer-details';
import { TimeFormat } from 'constants/date';
import { type SectionName } from 'constants/section-name';
import {
  CANNED_RESPONSE_AUTOTAGS_RELEASE_DATE,
  PRIVATE_SUGGESTED_CANNEDS_RELEASE_DATE,
} from 'constants/settings/canned-responses';
import { AVAILABILITY_SECTION_RELEASE_DATE } from 'constants/settings/chat-widget';
import { sourcesThatDisallowsDesktopApp, sourcesThatInstallsCodeAutomatically } from 'constants/sources';
import { ThemeName } from 'constants/themes';
import {
  getChatWindowGreetingClickedPropertyName,
  getChatWindowGreetingSkippedPropertyName,
  getFilteredSections,
} from 'helpers/agent-custom-properties';
import { anyToBoolean } from 'helpers/boolean';
import { shouldShowCannedResponsesPromoTooltip } from 'helpers/device';
import { type KeyMap } from 'helpers/interface';
import { getLoggedInAgentAccount, type WithAccountsState } from 'store/entities/accounts/selectors';
import { EMPTY_ARRAY } from 'store/features/custom-segments/constants';
import { getCreationTimestamp, getIsStarterPlan, type IWithSessionState } from 'store/features/session/selectors';
import type { IRequestsState } from 'store/requests/interfaces';
import {
  createHasFetchedSelector,
  createHasFetchFailedSelector,
  createRequestFetchingSelector,
} from 'store/requests/selectors';

import { FETCH_AGENT_CUSTOM_PROPERTIES, FETCH_METRICS, SET_AGENT_CUSTOM_PROPERTY } from './actions';
import {
  type AgentCustomPropertyDataType,
  AgentCustomPropertyName,
  type ICannedResponseAutotagsPromoCustomProperty,
  type IKnowledgeSourcesPromoCustomProperty,
  type IReplySuggestionsPromoCustomProperty,
  type SavedInvitationsProperty,
} from './interfaces';

export const DEFAULT_DETAILS_SECTIONS = ['chats', 'archives'];

export interface IWithAgentCustomPropertiesState {
  features: {
    agentCustomProperties: KeyMap<AgentCustomPropertyDataType>;
  };
  requests: IRequestsState;
}

export const getAgentCustomProperties = (state: IWithAgentCustomPropertiesState): KeyMap<AgentCustomPropertyDataType> =>
  state.features.agentCustomProperties;

export const getTicketPrivacyTooltipSeen = (state: IWithAgentCustomPropertiesState): boolean => {
  const agentCustomProperties = getAgentCustomProperties(state);

  if (agentCustomProperties) {
    const propertyValue = agentCustomProperties[AgentCustomPropertyName.TicketPrivacyTooltipSeen];

    return anyToBoolean(propertyValue);
  }

  return true;
};

export const getTicketStatusTooltipSeen = (state: IWithAgentCustomPropertiesState): boolean => {
  const agentCustomProperties = getAgentCustomProperties(state);

  if (agentCustomProperties) {
    const propertyValue = agentCustomProperties[AgentCustomPropertyName.TicketStatusTooltipSeen];

    return anyToBoolean(propertyValue);
  }

  return true;
};

export const getChatCannedResponsesTooltipSeen = (state: IWithAgentCustomPropertiesState): boolean => {
  const agentCustomProperties = getAgentCustomProperties(state);

  if (agentCustomProperties) {
    const propertyValue = agentCustomProperties[AgentCustomPropertyName.ChatCannedResponsesTooltipSeen];

    return anyToBoolean(propertyValue);
  }

  return true;
};

export const getShouldShowCannedResponsesTooltip = (
  state: IWithAgentCustomPropertiesState & IWithSessionState,
): boolean => {
  if (!shouldShowCannedResponsesPromoTooltip()) {
    return false;
  }

  const now = new Date();
  const creationDate = new Date(getCreationTimestamp(state));
  const licenseAgeInDays = differenceInCalendarDays(now, creationDate);
  const isStarterPlan = getIsStarterPlan(state);

  if (licenseAgeInDays >= 3 && isStarterPlan) {
    return !getChatCannedResponsesTooltipSeen(state);
  }

  return false;
};

export const getSectionsWithDetailsOpen = (state: IWithAgentCustomPropertiesState): string[] => {
  const agentCustomProperties = getAgentCustomProperties(state);
  if (!agentCustomProperties || !agentCustomProperties[AgentCustomPropertyName.SectionDetailsOpen]) {
    return DEFAULT_DETAILS_SECTIONS;
  }

  return agentCustomProperties[AgentCustomPropertyName.SectionDetailsOpen] as string[];
};

export const getAreDetailsOpen = (state: IWithAgentCustomPropertiesState, sectionName: SectionName): boolean => {
  const sectionsWithDetailsOpen = getSectionsWithDetailsOpen(state);

  return sectionsWithDetailsOpen.includes(sectionName);
};

export function getAgentCustomProperty(
  state: IWithAgentCustomPropertiesState,
  property: AgentCustomPropertyName,
): AgentCustomPropertyDataType {
  const agentCustomProperties = getAgentCustomProperties(state);

  return agentCustomProperties?.[property];
}

export const getAgentPinnedTags = (state: IWithAgentCustomPropertiesState): string[] => {
  return (getAgentCustomProperty(state, AgentCustomPropertyName.PinnedTags) as string[]) ?? (EMPTY_ARRAY as string[]);
};

export const getHasPendingInvites = (state: IWithAgentCustomPropertiesState): boolean => {
  return anyToBoolean(
    (getAgentCustomProperty(state, AgentCustomPropertyName.SavedInvitations) as SavedInvitationsProperty)?.length > 0,
  );
};

export const getPendingInvitesCount = (state: IWithAgentCustomPropertiesState): number => {
  return (getAgentCustomProperty(state, AgentCustomPropertyName.SavedInvitations) as SavedInvitationsProperty)?.length;
};

export function getChatWindowSkippedGreetingsCount(state: IWithAgentCustomPropertiesState): number {
  return +getAgentCustomProperty(state, AgentCustomPropertyName.ChatWindowGreetingsSkipped) || 0;
}

export function getChatWindowSkippedGreetingCount(state: IWithAgentCustomPropertiesState, greetingId: string): number {
  return +getAgentCustomProperty(state, getChatWindowGreetingSkippedPropertyName(greetingId)) || 0;
}

export function getChatWindowClickedGreetingCount(state: IWithAgentCustomPropertiesState, greetingId: string): number {
  return +getAgentCustomProperty(state, getChatWindowGreetingClickedPropertyName(greetingId)) || 0;
}

export function hasFetchedAgentCustomProperties(state: IWithAgentCustomPropertiesState): boolean {
  return createHasFetchedSelector([FETCH_AGENT_CUSTOM_PROPERTIES])(state);
}

export function hasSetAgentCustomProperty(state: IWithAgentCustomPropertiesState): boolean {
  return createHasFetchedSelector([SET_AGENT_CUSTOM_PROPERTY])(state);
}

export function isSettingAgentCustomProperty(state: IWithAgentCustomPropertiesState): boolean {
  return createRequestFetchingSelector([SET_AGENT_CUSTOM_PROPERTY])(state);
}

export function hasFetchedMetrics(state: IWithAgentCustomPropertiesState): boolean {
  return createHasFetchedSelector([FETCH_METRICS])(state);
}

export function hasFailedSetAgentCustomProperty(state: IWithAgentCustomPropertiesState): boolean {
  return createHasFetchFailedSelector([SET_AGENT_CUSTOM_PROPERTY])(state);
}

export function getIsAgentCustomPropertiesFetchFailed(state: IWithAgentCustomPropertiesState): boolean {
  return createHasFetchFailedSelector([FETCH_AGENT_CUSTOM_PROPERTIES])(state);
}

function getTooltipSeen(state: IWithAgentCustomPropertiesState, tooltipSeenProperty: AgentCustomPropertyName): boolean {
  if (!hasFetchedAgentCustomProperties(state)) {
    return true;
  }

  return anyToBoolean(getAgentCustomProperty(state, tooltipSeenProperty));
}

export function getUtmSource(state: IWithAgentCustomPropertiesState): string | null {
  const agentCustomProperties = getAgentCustomProperties(state);

  return agentCustomProperties ? (agentCustomProperties[AgentCustomPropertyName.UtmSource] as string) : null;
}

export const getTimeFormat = (state: IWithAgentCustomPropertiesState): TimeFormat => {
  return (getAgentCustomProperty(state, AgentCustomPropertyName.TimeFormat) as TimeFormat) || TimeFormat.F12;
};

export const getIs24hFormat = (state: IWithAgentCustomPropertiesState): boolean => {
  return getTimeFormat(state) === TimeFormat.F24;
};

export function getCustomSoundsTooltipSeen(state: IWithAgentCustomPropertiesState): boolean {
  return getTooltipSeen(state, AgentCustomPropertyName.CustomSoundsTooltipSeen);
}

export function getCustomerDetailsSectionSelected(state: IWithAgentCustomPropertiesState): string[] | null {
  const customDetailsSectionsSelected = getAgentCustomProperty(
    state,
    AgentCustomPropertyName.CustomerDetailsSectionSelected,
  ) as string[];

  return Array.isArray(customDetailsSectionsSelected) ? customDetailsSectionsSelected : null;
}

export function getCustomerDetailsSectionsOrder(state: IWithAgentCustomPropertiesState): string[] {
  const customDetailsSectionsOrder = getAgentCustomProperty(
    state,
    AgentCustomPropertyName.CustomerDetailsSectionsOrder,
  ) as string[];

  return Array.isArray(customDetailsSectionsOrder)
    ? getFilteredSections(customDetailsSectionsOrder)
    : getFilteredSections(CUSTOMER_DETAILS_DEFAULT_SECTIONS);
}

export function getIntegrationsOrder(state: IWithAgentCustomPropertiesState): string[] {
  const integrationsOrder = getAgentCustomProperty(state, AgentCustomPropertyName.IntegrationsOrder) as string[];

  return Array.isArray(integrationsOrder) ? integrationsOrder : (EMPTY_ARRAY as string[]);
}

export function getDesktopAppInstalled(state: IWithAgentCustomPropertiesState): AgentCustomPropertyDataType {
  return getAgentCustomProperty(state, AgentCustomPropertyName.DesktopAppInstalled);
}

export function getNewVisitorTooltipViewed(state: IWithAgentCustomPropertiesState): AgentCustomPropertyDataType {
  return getAgentCustomProperty(state, AgentCustomPropertyName.NewVisitorTooltipViewed);
}

export function getHelpDeskBoosterTooltipSeen(state: IWithAgentCustomPropertiesState): boolean {
  return getTooltipSeen(state, AgentCustomPropertyName.HelpDeskBoosterTooltipSeen);
}

export function getHomeScreenChecklistClosed(state: IWithAgentCustomPropertiesState): boolean {
  return anyToBoolean(getAgentCustomProperty(state, AgentCustomPropertyName.HomeScreenChecklistClosed));
}

export function getCustomersSegentsTourSeen(state: IWithAgentCustomPropertiesState): boolean {
  return anyToBoolean(getAgentCustomProperty(state, AgentCustomPropertyName.CustomersSegmentsTourDone));
}

export function getOpenChatTooltipSeen(state: IWithAgentCustomPropertiesState): boolean {
  return getTooltipSeen(state, AgentCustomPropertyName.OpenChatTooltipSeen);
}

export function getNumberOfStatusReminderDismisses(state: IWithAgentCustomPropertiesState): number {
  return parseInt(getAgentCustomProperty(state, AgentCustomPropertyName.StatusReminderSkips) as string, 10) || 0;
}

export function getReportViewsTourDone(state: IWithAgentCustomPropertiesState): boolean {
  return anyToBoolean(getAgentCustomProperty(state, AgentCustomPropertyName.ReportViewsTourDone));
}

export function getReportViewsIntroTooltipSeen(state: IWithAgentCustomPropertiesState): boolean {
  return anyToBoolean(getAgentCustomProperty(state, AgentCustomPropertyName.ReportViewsIntroTooltipSeen));
}

export function getRichGreetingEditTooltipSeen(state: IWithAgentCustomPropertiesState): boolean {
  return getTooltipSeen(state, AgentCustomPropertyName.RichGreetingEditTooltipSeen);
}

export function getMessagingModeTicketFormPromoBannerSeen(state: IWithAgentCustomPropertiesState): boolean {
  return anyToBoolean(getAgentCustomProperty(state, AgentCustomPropertyName.MessagingModeTicketFormPromoBannerSeen));
}

export function getShopifyCustomerInsightsTooltipSeen(state: IWithAgentCustomPropertiesState): boolean {
  return getTooltipSeen(state, AgentCustomPropertyName.ShopifyCustomerInsightsTooltipSeen);
}

export function getGreetingsTourSeen(state: IWithAgentCustomPropertiesState): boolean {
  return anyToBoolean(getAgentCustomProperty(state, AgentCustomPropertyName.GreetingsTourSeen));
}

export function getReportsTourSeen(state: IWithAgentCustomPropertiesState): boolean {
  return anyToBoolean(getAgentCustomProperty(state, AgentCustomPropertyName.ReportsTourSeen));
}

export function getCustomerListTourSeen(state: IWithAgentCustomPropertiesState): boolean {
  return anyToBoolean(getAgentCustomProperty(state, AgentCustomPropertyName.CustomerListTourSeen));
}

export function getTargetedMessagesTooltipSeen(state: IWithAgentCustomPropertiesState): boolean {
  if (!hasFetchedAgentCustomProperties(state)) {
    return true;
  }

  return anyToBoolean(getAgentCustomProperty(state, AgentCustomPropertyName.TargetedMessagesTooltipSeen));
}

export function getFiltersOperatorsTooltipSeen(state: IWithAgentCustomPropertiesState): boolean {
  return getTooltipSeen(state, AgentCustomPropertyName.FiltersOperatorsTooltipSeen);
}

export function getChatsRoutingSettingsTooltipSeen(state: IWithAgentCustomPropertiesState): boolean {
  return getTooltipSeen(state, AgentCustomPropertyName.ChatsRoutingSettingsTooltipSeen);
}

export function getCannedResponseHeaderVisible(state: IWithAgentCustomPropertiesState): boolean {
  const cannedResponsesHeaderVisible = getAgentCustomProperty(
    state,
    AgentCustomPropertyName.CannedResponseHeaderVisible,
  );

  // We want to show all agents canned responses header on first time
  // Since we don't have a method to trigger this prop to every agent we check if it's initiated at all
  if (typeof cannedResponsesHeaderVisible === 'undefined') {
    return true;
  }

  return anyToBoolean(cannedResponsesHeaderVisible);
}

export function getSuggestedResponseVisible(state: IWithAgentCustomPropertiesState): boolean {
  const suggestedResponseVisible = getAgentCustomProperty(state, AgentCustomPropertyName.SuggestedResponseVisible);

  if (typeof suggestedResponseVisible === 'undefined') {
    return true;
  }

  return anyToBoolean(suggestedResponseVisible);
}

export function getAICannedAutocompleteVisible(state: IWithAgentCustomPropertiesState): boolean {
  const aICannedAutocompleteVisible = getAgentCustomProperty(state, AgentCustomPropertyName.AICannedAutocomplete);

  if (typeof aICannedAutocompleteVisible === 'undefined') {
    return true;
  }

  return anyToBoolean(aICannedAutocompleteVisible);
}

export function getAICannedAutocompletTooltipSeen(state: IWithAgentCustomPropertiesState): boolean {
  const aICannedAutocompleteTooltipSeen = getAgentCustomProperty(
    state,
    AgentCustomPropertyName.AICannedAutocompleteTooltip,
  );

  if (typeof aICannedAutocompleteTooltipSeen === 'undefined') {
    return false;
  }

  return anyToBoolean(aICannedAutocompleteTooltipSeen);
}

export const getChatTakeoverTooltipSeen = (state: IWithAgentCustomPropertiesState): boolean => {
  return getTooltipSeen(state, AgentCustomPropertyName.ChatTakeoverTooltipSeen);
};

export const getAddPrivateNoteTooltipSeen = (state: IWithAgentCustomPropertiesState): boolean => {
  return getTooltipSeen(state, AgentCustomPropertyName.AddPrivateNoteTooltipSeen);
};

export function getCurrentTheme(state: IWithAgentCustomPropertiesState): ThemeName {
  const theme = getAgentCustomProperty(state, AgentCustomPropertyName.CurrentTheme);

  return !theme ? ThemeName.Light : (theme as ThemeName);
}

export function isDarkModeEnabled(state: IWithAgentCustomPropertiesState): boolean {
  const theme = getCurrentTheme(state);

  return theme === ThemeName.Dark;
}

export const getPasteAttachmentTooltipSeen = (state: IWithAgentCustomPropertiesState): boolean => {
  return getTooltipSeen(state, AgentCustomPropertyName.PasteAttachmentTooltipSeen);
};

export const getMessagingInQueuePromoBannerSeen = (state: IWithAgentCustomPropertiesState): boolean => {
  return getTooltipSeen(state, AgentCustomPropertyName.MessagingInQueuePromoBannerSeen);
};

export const getLeaveAFeedbackBannerSeen = (state: IWithAgentCustomPropertiesState): boolean => {
  return anyToBoolean(getAgentCustomProperty(state, AgentCustomPropertyName.LeaveAFeedbackBannerSeen));
};

export const getAddWebsiteAsSourceBannerClosed = (state: IWithAgentCustomPropertiesState): boolean => {
  return anyToBoolean(getAgentCustomProperty(state, AgentCustomPropertyName.AddWebsiteAsSourceBannerClosed));
};

export const getPreviewSourceTooltipClosed = (state: IWithAgentCustomPropertiesState): boolean => {
  return anyToBoolean(getAgentCustomProperty(state, AgentCustomPropertyName.PreviewSourceTooltipClosed));
};

export const getAskForEmailBannerSeen = (state: IWithAgentCustomPropertiesState): boolean => {
  return anyToBoolean(getAgentCustomProperty(state, AgentCustomPropertyName.AskForEmailBannerSeen));
};

export const getIsLicenseCreatedBeforeAvailabilitySection = (
  state: IWithSessionState & IWithAgentCustomPropertiesState,
): boolean => {
  const creationDate = new Date(getCreationTimestamp(state));

  return isBefore(creationDate, AVAILABILITY_SECTION_RELEASE_DATE);
};

export const getIsLicenseCreatedBeforeCannedResponseAutotagsFeature = (
  state: IWithSessionState & IWithAgentCustomPropertiesState,
): boolean => {
  const creationDate = new Date(getCreationTimestamp(state));

  return isBefore(creationDate, CANNED_RESPONSE_AUTOTAGS_RELEASE_DATE);
};

export const getExportCSVInappSeen = (state: IWithAgentCustomPropertiesState): boolean => {
  return anyToBoolean(getAgentCustomProperty(state, AgentCustomPropertyName.ExportCSVInappSeen));
};

export function isDesktopAppDisallowedByLicenseSource(state: IWithAgentCustomPropertiesState): boolean {
  const utmSource = getUtmSource(state);

  return sourcesThatDisallowsDesktopApp.includes(utmSource);
}

export function isCodeInstalledAutomaticallyForLicenseSource(state: IWithAgentCustomPropertiesState): boolean {
  const utmSource = getUtmSource(state);

  return sourcesThatInstallsCodeAutomatically.includes(utmSource);
}

export const getTeamApproveAllSeen = (state: IWithAgentCustomPropertiesState): boolean => {
  const arePropertiesAvailable = hasFetchedAgentCustomProperties(state);

  if (!arePropertiesAvailable) {
    return true;
  }

  return anyToBoolean(getAgentCustomProperty(state, AgentCustomPropertyName.TeamApproveAllSeen));
};

export const getSuggestedResponseTooltipSeen = (state: IWithAgentCustomPropertiesState): boolean => {
  const arePropertiesAvailable = hasFetchedAgentCustomProperties(state);

  if (!arePropertiesAvailable) {
    return true;
  }

  return anyToBoolean(getAgentCustomProperty(state, AgentCustomPropertyName.SuggestedResponseTooltipSeen));
};

export const getIsLicenseCreatedAfterSuggestedResponse = (
  state: IWithSessionState & IWithAgentCustomPropertiesState,
): boolean => {
  const creationDate = new Date(getCreationTimestamp(state));

  return isAfter(creationDate, PRIVATE_SUGGESTED_CANNEDS_RELEASE_DATE);
};

export const getChatSummaryTooltipSeen = (state: IWithAgentCustomPropertiesState): boolean => {
  const arePropertiesAvailable = hasFetchedAgentCustomProperties(state);

  if (!arePropertiesAvailable) {
    return true;
  }

  return anyToBoolean(
    getAgentCustomProperty(state, AgentCustomPropertyName.ChatSummaryTooltipSeen as AgentCustomPropertyName),
  );
};

export const getCannedResponseAutotagsPromoSeen = (
  state: IWithAgentCustomPropertiesState,
): ICannedResponseAutotagsPromoCustomProperty => {
  const arePropertiesAvailable = hasFetchedAgentCustomProperties(state);
  if (!arePropertiesAvailable) {
    return {
      cannedResponseListTooltipSeen: true,
      cannedResponseChatModalSeen: true,
      cannedResponseListSeen: true,
    };
  }

  const properties = (getAgentCustomProperty(state, AgentCustomPropertyName.CannedResponseAutotagsPromoSeen) ||
    {}) as ICannedResponseAutotagsPromoCustomProperty;

  return {
    cannedResponseListTooltipSeen: false,
    cannedResponseChatModalSeen: false,
    cannedResponseListSeen: false,
    ...properties,
  };
};

export const getCannedResponseAutotagsChatModalSeen = (state: IWithAgentCustomPropertiesState): boolean =>
  getCannedResponseAutotagsPromoSeen(state).cannedResponseChatModalSeen;

export const getKnowledgeSourcesPromoSeen = (
  state: IWithAgentCustomPropertiesState,
): IKnowledgeSourcesPromoCustomProperty => {
  const arePropertiesAvailable = hasFetchedAgentCustomProperties(state);

  if (!arePropertiesAvailable) {
    return {
      knowledgeSourcesListSeen: true,
      knowledgeSourcesTooltipSeen: true,
    };
  }

  const properties = (getAgentCustomProperty(state, AgentCustomPropertyName.KnowledgeSourcesPromoSeen) ||
    {}) as IKnowledgeSourcesPromoCustomProperty;

  return {
    knowledgeSourcesListSeen: false,
    knowledgeSourcesTooltipSeen: false,
    ...properties,
  };
};

export const getKnowledgeSourcesListSeen = (state: IWithAgentCustomPropertiesState): boolean =>
  getKnowledgeSourcesPromoSeen(state).knowledgeSourcesListSeen;

export const getReplySuggestionsPromo = (
  state: IWithAgentCustomPropertiesState,
): IReplySuggestionsPromoCustomProperty | null => {
  const arePropertiesAvailable = hasFetchedAgentCustomProperties(state);

  if (!arePropertiesAvailable) {
    return null;
  }

  const properties = (getAgentCustomProperty(state, AgentCustomPropertyName.ReplySuggestionsPromo) ||
    {}) as IReplySuggestionsPromoCustomProperty;

  return {
    replySuggestionCount: 0,
    dateOfFirstUsage: null,
    displayedSprigFormCount: 0,
    ...properties,
  };
};

export function getChatsListSentimentVisible(state: IWithAgentCustomPropertiesState): boolean {
  const chatsListSentimentProperty = getAgentCustomProperty(state, AgentCustomPropertyName.ChatsListSentiment);

  // We want to show to all agents sentiment
  // Since we don't have a method to trigger this prop to every agent we check if it's initiated at all
  if (typeof chatsListSentimentProperty === 'undefined') {
    return true;
  }

  return anyToBoolean(chatsListSentimentProperty);
}

export function getIsReplySuggestionsBannerClosed(state: IWithAgentCustomPropertiesState): boolean {
  return anyToBoolean(getAgentCustomProperty(state, AgentCustomPropertyName.ReplySuggestionsBannerClosed));
}

export function getIsReplySuggestionsLimitsAlertClosed(state: IWithAgentCustomPropertiesState): boolean {
  return anyToBoolean(getAgentCustomProperty(state, AgentCustomPropertyName.ReplySuggestionsLimitsAlertClosed));
}

export function getIsReplySuggestionsLimitsAlertSeen(state: IWithAgentCustomPropertiesState): boolean {
  return anyToBoolean(getAgentCustomProperty(state, AgentCustomPropertyName.ReplySuggestionsLimitsAlertSeen));
}

export const getExceededSessionsInfo = (
  state: IWithAgentCustomPropertiesState,
): { seats: number; exceededSessionsCount: number } => {
  const arePropertiesAvailable = hasFetchedAgentCustomProperties(state);

  if (!arePropertiesAvailable) {
    return { seats: 0, exceededSessionsCount: 0 };
  }

  const exceededSessionsInfo = getAgentCustomProperty(state, AgentCustomPropertyName.ExceededSessionsCount);

  if (!exceededSessionsInfo) {
    return { seats: 0, exceededSessionsCount: 0 };
  }

  const sessionsInfo: { seats: number; exceededSessionsCount: number } = exceededSessionsInfo as {
    seats: number;
    exceededSessionsCount: number;
  };

  return sessionsInfo;
};

export const getPaymentErrorOnAccessBlockedForAgent = (state: IWithAgentCustomPropertiesState): string | null => {
  const paymentErrorOnAccessBlocked = getAgentCustomProperty(
    state,
    AgentCustomPropertyName.PaymentErrorOnAccessBlocked,
  );

  if (
    paymentErrorOnAccessBlocked === undefined ||
    paymentErrorOnAccessBlocked === '0' ||
    paymentErrorOnAccessBlocked === 0
  ) {
    return null;
  }

  return paymentErrorOnAccessBlocked as string;
};

export const getIntentCampaignTooltipSeen = (state: IWithAgentCustomPropertiesState): boolean => {
  const arePropertiesAvailable = hasFetchedAgentCustomProperties(state);

  if (!arePropertiesAvailable) {
    return true;
  }

  return anyToBoolean(
    getAgentCustomProperty(state, AgentCustomPropertyName.IntentCampaignTooltipSeen as AgentCustomPropertyName),
  );
};

export const getCampaignPromoBannerHidden = (state: IWithAgentCustomPropertiesState): boolean => {
  const arePropertiesAvailable = hasFetchedAgentCustomProperties(state);

  if (!arePropertiesAvailable) {
    return true;
  }

  return anyToBoolean(
    getAgentCustomProperty(state, AgentCustomPropertyName.CampaignPromoBannerHidden as AgentCustomPropertyName),
  );
};

export const getExitIntentPromoBannerHidden = (state: IWithAgentCustomPropertiesState): boolean => {
  const arePropertiesAvailable = hasFetchedAgentCustomProperties(state);

  if (!arePropertiesAvailable) {
    return true;
  }

  return anyToBoolean(
    getAgentCustomProperty(state, AgentCustomPropertyName.ExitIntentPromoBannerHidden as AgentCustomPropertyName),
  );
};

export const getReplySuggestionsOnboardingSeen = (state: IWithAgentCustomPropertiesState): boolean => {
  const arePropertiesAvailable = hasFetchedAgentCustomProperties(state);

  if (!arePropertiesAvailable) {
    return true;
  }

  return anyToBoolean(
    getAgentCustomProperty(state, AgentCustomPropertyName.ReplySuggestionsOnboardingSeen as AgentCustomPropertyName),
  );
};

export const getSpotlightActionsOnboardingSeen = (state: IWithAgentCustomPropertiesState): boolean => {
  const arePropertiesAvailable = hasFetchedAgentCustomProperties(state);

  if (!arePropertiesAvailable) {
    return true;
  }

  return anyToBoolean(
    getAgentCustomProperty(state, AgentCustomPropertyName.SpotlightActionsOnboardingSeen as AgentCustomPropertyName),
  );
};

export const getCopilotOnboardingSeen = (state: IWithAgentCustomPropertiesState): boolean => {
  const arePropertiesAvailable = hasFetchedAgentCustomProperties(state);

  if (!arePropertiesAvailable) {
    return true;
  }

  return anyToBoolean(
    getAgentCustomProperty(state, AgentCustomPropertyName.OnePopoverOnboardingSeen as AgentCustomPropertyName),
  );
};

export function getIsDictionaryAutocompleteEnabled(state: IWithAgentCustomPropertiesState): boolean {
  const arePropertiesAvailable = hasFetchedAgentCustomProperties(state);

  if (!arePropertiesAvailable) {
    return true;
  }

  const dictionaryAutocompleteEnabled = getAgentCustomProperty(
    state,
    AgentCustomPropertyName.DictionaryAutocomplete as AgentCustomPropertyName,
  );

  if (dictionaryAutocompleteEnabled === undefined) {
    return true;
  }

  return anyToBoolean(dictionaryAutocompleteEnabled);
}

export function getShouldShowAutomateTooltip(
  state: IWithAgentCustomPropertiesState & IWithSessionState & WithAccountsState,
): boolean {
  const arePropertiesAvailable = hasFetchedAgentCustomProperties(state);
  if (!arePropertiesAvailable) {
    return false;
  }

  const hasVisitedAutomate = anyToBoolean(getAgentCustomProperty(state, AgentCustomPropertyName.AutomateVisited));
  if (hasVisitedAutomate) {
    return false;
  }

  const loggedInAgentCreationDate = getLoggedInAgentAccount(state)?.createdAt;
  if (!loggedInAgentCreationDate) {
    return false;
  }

  return isBefore(new Date(loggedInAgentCreationDate), AUTOMATE_SECTION_RELEASE_DATE);
}

export function getIsKnowledgeHubAllSourcesBannerClosed(state: IWithAgentCustomPropertiesState): boolean {
  return anyToBoolean(getAgentCustomProperty(state, AgentCustomPropertyName.KnowledgeHubAllSourcesBannerClosed));
}

export function getDownloadDesktopAppNextVisibilityDate(state: IWithAgentCustomPropertiesState): string | undefined {
  return getAgentCustomProperty(state, AgentCustomPropertyName.DownloadDesktopAppBannerNextVisibilityDate) as string;
}

export function getNativeTicketsSubsetBannerVisibilityDate(state: IWithAgentCustomPropertiesState): string {
  return getAgentCustomProperty(state, AgentCustomPropertyName.NativeTicketsSubsetBannerNextVisibilityDate) as string;
}

export function getContextModalSeen(state: IWithAgentCustomPropertiesState): boolean {
  const daysUntilMigration = getDaysUntilMigration();

  if (daysUntilMigration < 0) {
    return getContextModalSeenAfterMigration(state);
  }
  switch (daysUntilMigration) {
    case 1:
      return getContextModalSeen1DayBeforeMigration(state);
    case 4:
      return getContextModalSeen4DaysBeforeMigration(state);
    default:
      return false;
  }
}

function getContextModalSeenAfterMigration(state: IWithAgentCustomPropertiesState): boolean {
  return anyToBoolean(getAgentCustomProperty(state, AgentCustomPropertyName.ContextModalSeenAfterMigration));
}

function getContextModalSeen4DaysBeforeMigration(state: IWithAgentCustomPropertiesState): boolean {
  return anyToBoolean(
    getAgentCustomProperty(state, AgentCustomPropertyName.ContextModalSeen4DaysBeforeMigration) as boolean,
  );
}

function getContextModalSeen1DayBeforeMigration(state: IWithAgentCustomPropertiesState): boolean {
  return anyToBoolean(
    getAgentCustomProperty(state, AgentCustomPropertyName.ContextModalSeen1DayBeforeMigration) as boolean,
  );
}

export const getPriorityChatTooltipSeen = (state: IWithAgentCustomPropertiesState): boolean => {
  const arePropertiesAvailable = hasFetchedAgentCustomProperties(state);

  if (!arePropertiesAvailable) {
    return false;
  }

  return anyToBoolean(
    getAgentCustomProperty(state, AgentCustomPropertyName.PriorityChatTooltip as AgentCustomPropertyName),
  );
};

export const getAppInterviewCallScheduledTo = (
  state: IWithAgentCustomPropertiesState,
  propertyName: AgentCustomPropertyName,
): number | null => {
  const arePropertiesAvailable = hasFetchedAgentCustomProperties(state);

  if (!arePropertiesAvailable) {
    return 0;
  }

  const propertyValue = getAgentCustomProperty(state, propertyName);

  if (typeof propertyValue === 'number') {
    return propertyValue;
  }

  const numPropertyValue = parseInt(propertyValue as string, 10);

  return Number.isNaN(numPropertyValue) ? null : numPropertyValue;
};
