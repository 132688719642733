// @ts-strict-ignore
import { type AppsSection } from 'constants/apps/events';
import { Section } from 'constants/section';
import { type AutomateSection } from 'helpers/analytics-automate';

import { type SettingsSection } from './analytics-settings';
import type { IKeyValue } from './url';

const zapierBase = 'https://hooks.zapier.com/hooks/catch/3033090';

export enum EventPlace {
  AccountDetails = 'Account Details',
  AddCannedResponseModal = 'Add canned response modal',
  Application = 'Application',
  Automate = 'Automate',
  Apps = 'Apps',
  Archives = 'Archives',
  Banner = 'Banner',
  Bots = 'Bots',
  ChatBots = 'Chatbots',
  ChatWidgetUpgrade = 'Chat Widget Upgrade',
  Chats = 'Chats',
  CompanyDetailsModal = 'Company details',
  ComparePlansModal = 'Compare plans modal',
  Console = 'Console',
  Contacts = 'Contacts',
  CustomerDetails = 'Customer details',
  CustomerUpdate = 'Customer update',
  ContextModal = 'Context modal',
  DarkMode = 'Dark mode',
  DevInstructions = 'Dev instructions',
  EngageCampaigns = 'Engage - Campaigns',
  EngageTraffic = 'Engage - Traffic',
  EngageGoals = 'Engage - Goals',
  GreetingEdit = 'Greeting Edit',
  Greetings = 'Greetings',
  HelpMenu = 'Help menu',
  HomeScreen = 'Home',
  Insights = 'Insights',
  InstallCode = 'Install code',
  Installation = 'Installation',
  Invoices = 'Invoices',
  KBLandingPage = 'KbForLcTrial',
  HDMenuPromoPage = 'HelpDesk Menu Promo Page',
  KeyboardShortcuts = 'Keyboard shortcuts',
  LicenseExpired = 'License expired',
  LicenseUpselling = 'License upselling',
  Lockscreen = 'Lockscreen',
  LogoutScreen = 'Logout screen',
  MobileProfileMenu = 'Mobile profile menu',
  NewAvailabilityPromoModal = 'New availability promo modal',
  NewAvailabilityPromoTooltip = 'New availability promo tooltip',
  Notification = 'Notification',
  NotificationBar = 'Notification bar',
  NotificationsModal = 'Notifications modal',
  Onboarding = 'Onboarding',
  OnboardingGreetings = 'Onboarding greetings',
  One = 'One', // should we change track events?
  ProductSwitcher = 'Product switcher',
  ProfileMenu = 'Profile menu',
  ReportBugGlobalModal = 'Report bug global modal',
  Reports = 'Reports',
  RoutingRules = 'Routing rules',
  SendInstructionModal = 'Send code instructions modal',
  Settings = 'Settings',
  SignOut = 'Signout page',
  SmartClient = 'Smart client',
  SomethingWentWrongModal = 'Something went wrong modal',
  StartupError = 'Startup error page',
  StatusReminder = 'Status Reminder',
  StopThisChatModal = 'Stop this chat modal',
  Subscription = 'Subscription',
  Team = 'Team',
  Tickets = 'Tickets',
  TooManyAgents = 'Too many agents',
  Tooltip = 'Tooltip',
  TopBar = 'Top bar',
  Tour = 'Tour',
  Traffic = 'Traffic',
  TrafficButton = 'Traffic button',
  TransferChat = 'Transfer chat',
  TrialExpiringModal = 'Trial expiring modal',
  UnassignedChatsNotification = 'Unassigned chats notification',
  WorkScheduler = 'Work Scheduler',
  SessionsLimitExceededModal = 'Sessions limit exceeded modal',
  AccessBlocked = 'Access blocked page',
  PastDueModal = 'Past due modal',
  Webhooks = 'Webhooks',
  IntentCampaignModal = 'Intent campaign modal',
  Spotlight = 'Spotlight',
}

export type EventSubsection = SettingsSection | AppsSection | AutomateSection;

export enum EventAdditionalProperty {
  Action = 'action',
  Integration = 'integration',
  Place = 'place',
  Reason = 'reason',
  Shortcut = 'shortcut',
  Source = 'source',
  Target = 'target',
  Type = 'type',
}

export enum EventAdditionalPropertyValue {
  ChatFeed = 'chat feed',
  List = 'list',
  SystemMessage = 'system message',
  Menu = 'menu',
  Queue = 'queue',
  Spotlight = 'spotlight',
  Modal = 'modal',
}

enum DataLayerEvent {
  CustomDimensions = 'custom_dimensions',
}

function sendDataLayerEvent(event: string, eventDetails: IKeyValue<unknown>): void {
  const dataLayer = window.dataLayer || [];

  dataLayer.push({ event, ...eventDetails });
}

export function setDataLayerCustomDimensions(eventDetails: Record<string, unknown>): void {
  sendDataLayerEvent(DataLayerEvent.CustomDimensions, eventDetails);
}

export function trackGTMEvent(event: string, eventDetails?: IKeyValue<unknown>): void {
  sendDataLayerEvent(event, eventDetails);
}

export function trackGAEvent(action: string, opts?: unknown): void {
  window.ga(action, opts);
}

export function trackZapierEvent(action: string): Promise<Response> {
  try {
    return fetch(zapierBase + action);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export function convertSectionToEventPlace(sectionName?: Section): EventPlace {
  switch (sectionName) {
    case Section.Chats:
      return EventPlace.Chats;
    case Section.Onboarding:
      return EventPlace.Onboarding;
    case Section.InstallCode:
      return EventPlace.InstallCode;
    case Section.Subscription:
      return EventPlace.Subscription;
    case Section.Traffic:
      return EventPlace.Traffic;
    case Section.Tickets:
      return EventPlace.Tickets;
    case Section.Reports:
      return EventPlace.Reports;
    case Section.Archives:
      return EventPlace.Archives;

    default:
      return null;
  }
}
