import { type FC, useMemo } from 'react';

import { Tag, SideNavigation, SideNavigationGroup } from '@livechat/design-system-react-components';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { SideNavigationItem } from 'components/side-navigation-v2/SideNavigationItem';
import { AbsoluteAutomateRoutes, AutomateNavigationItemId } from 'constants/automate/constants';
import { Feature } from 'constants/feature';
import { useParamSelector } from 'hooks/use-param-selector';
import {
  getCanAccessCannedResponses,
  getCanManageAutoAccessRules,
  getCanUseFeature,
} from 'store/features/session/selectors';

import { type AutomateSideNavigationItem } from './interfaces';
import { KnowledgeSourcesItem } from './KnowledgeSourcesCustomItem';

import * as styles from './styles';

export const AutomateSideNavigation: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isRoutingRulesSectionAccessible = useSelector(getCanManageAutoAccessRules);
  const isCannedResponsesSectionAccessible = useSelector(getCanAccessCannedResponses);
  const canUseKnowledgeHub = useParamSelector(getCanUseFeature, Feature.KnowledgeHub);

  const items: AutomateSideNavigationItem[] = useMemo(
    () =>
      [
        {
          id: AutomateNavigationItemId.Overview,
          name: 'Overview',
          path: AbsoluteAutomateRoutes.Overview,
          hasAccess: true,
        },
        {
          id: AutomateNavigationItemId.Chatbots,
          name: 'Chatbots',
          path: AbsoluteAutomateRoutes.Chatbots,
          hasAccess: true,
        },
        {
          id: AutomateNavigationItemId.KnowledgeHub,
          name: 'Knowledge hub',
          path: canUseKnowledgeHub ? undefined : AbsoluteAutomateRoutes.KnowledgeHub,
          hasAccess: true,
          children: canUseKnowledgeHub
            ? [
                {
                  id: AutomateNavigationItemId.KnowledgeHubAllSources,
                  name: 'All sources',
                  path: AbsoluteAutomateRoutes.KnowledgeHubAllSources,
                  hasAccess: canUseKnowledgeHub,
                  customLabelComponent: KnowledgeSourcesItem,
                  className: styles.knowledgeSourcesItem,
                },
                {
                  id: AutomateNavigationItemId.KnowledgeHubWebsites,
                  name: 'Websites',
                  path: AbsoluteAutomateRoutes.KnowledgeHubWebsites,
                  hasAccess: canUseKnowledgeHub,
                  customLabelComponent: KnowledgeSourcesItem,
                  className: styles.knowledgeSourcesItem,
                },
                {
                  id: AutomateNavigationItemId.KnowledgeHubPDF,
                  name: 'PDF files',
                  path: AbsoluteAutomateRoutes.KnowledgeHubPDF,
                  hasAccess: canUseKnowledgeHub,
                  customLabelComponent: KnowledgeSourcesItem,
                  className: styles.knowledgeSourcesItem,
                },
              ]
            : undefined,
        },
        {
          id: AutomateNavigationItemId.CannedResponses,
          name: 'Canned responses',
          hasAccess: isCannedResponsesSectionAccessible,
          children: [
            {
              id: AutomateNavigationItemId.ResponsesList,
              name: 'Responses list',
              path: AbsoluteAutomateRoutes.CannedResponses,
              hasAccess: isCannedResponsesSectionAccessible,
            },
            {
              id: AutomateNavigationItemId.SuggestedResponses,
              name: 'Suggested responses',
              path: AbsoluteAutomateRoutes.SuggestedResponses,
              hasAccess: isCannedResponsesSectionAccessible,
            },
          ],
        },
        {
          id: AutomateNavigationItemId.GroupsRouting,
          name: 'Routing rules',
          path: AbsoluteAutomateRoutes.GroupsRouting,
          hasAccess: isRoutingRulesSectionAccessible,
        },
        {
          id: AutomateNavigationItemId.Workflows,
          name: 'Workflows',
          path: AbsoluteAutomateRoutes.Workflows,
          hasAccess: true,
        },
      ].filter((item) => item.hasAccess),
    [isRoutingRulesSectionAccessible, canUseKnowledgeHub, isCannedResponsesSectionAccessible],
  );

  const workflowLabel = (
    <>
      Workflows{' '}
      <Tag className={styles.betaTag}>
        <span>Beta</span>
      </Tag>
    </>
  );

  return (
    <SideNavigation title="Automate" noGaps>
      {items.map((item) => {
        if (item.children && item.children.length) {
          return (
            <SideNavigationGroup key={item.name} label={item.name} isCollapsible>
              {item.children.map((child) => {
                const CustomComponent = child.customLabelComponent;
                const isActive = child.path === pathname;

                return (
                  <SideNavigationItem
                    key={child.name}
                    label={CustomComponent ? <CustomComponent isActive={isActive} item={child} /> : <>{child.name}</>}
                    onClick={() => navigate(child.path)}
                    isActive={isActive}
                    className={child.className ?? ''}
                  />
                );
              })}
            </SideNavigationGroup>
          );
        }

        const label = item.name === 'Workflows' ? workflowLabel : item.name;

        return (
          <SideNavigationItem
            isMainEntry
            key={item.name}
            label={label}
            onClick={() => navigate(item.path ?? AbsoluteAutomateRoutes.Base)}
            isActive={item?.path === pathname}
            className={item.className ?? ''}
          />
        );
      })}
    </SideNavigation>
  );
};
