import * as Sentry from '@sentry/browser';
import debug from 'debug';

import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { ServerError } from 'constants/server-error';
import { isWithApiError } from 'helpers/request-decoder';
import { isConfigurationApiError } from 'services/connectivity/configuration-api/type-guards';
import { isAccountsApiError } from 'services/connectivity/global-accounts-api/type-guards';
import { handleServerError } from 'services/server/handle-server-error';
import { handleAccountsApiError, handleApiError, handleConfigurationApiError } from 'services/server/login-failure';

const log = debug(DebugLogsNamespace.AppServerConnection);

export async function handleStartupError(error: unknown): Promise<void> {
  if (isConfigurationApiError(error)) {
    log('Received configuration API error', error);
    Sentry.addBreadcrumb({
      type: 'debug',
      category: 'startup-error',
      message: `Configuration API error: ${JSON.stringify(error)}`,
    });
    await handleConfigurationApiError(error);
  } else if (isAccountsApiError(error)) {
    log('Received accounts API error', error);
    Sentry.addBreadcrumb({
      type: 'debug',
      category: 'startup-error',
      message: `Accounts API error: ${JSON.stringify(error)}`,
    });
    await handleAccountsApiError(error);
  } else if (isWithApiError(error)) {
    log('Received status error', error);
    Sentry.addBreadcrumb({
      type: 'debug',
      category: 'startup-error',
      message: `API error: ${JSON.stringify(error)}`,
    });
    await handleApiError(error);
  } else {
    log('Critical error fetching startup data', error);
    Sentry.addBreadcrumb({
      type: 'debug',
      category: 'startup-error',
      message: `Unexpected error: ${JSON.stringify(error)}`,
    });
    await handleServerError(ServerError.UnexpectedError, error);
  }
}
