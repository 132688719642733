import { type SagaIterator } from 'redux-saga';
import { put, select, take, takeEvery } from 'redux-saga/effects';

import { GlobalModal } from 'constants/global-modal';
import { RequestAction } from 'store/entities/actions';
import { SubscriptionActionNames } from 'store/entities/subscription/actions';
import { getSubscription } from 'store/entities/subscription/selectors';
import { hasFetchedAgentCustomProperties as getHasFetchedAgentCustomProperties } from 'store/features/agent-custom-properties/selectors';

import { AGENT_CUSTOM_PROPERTIES } from '../agent-custom-properties/actions';
import { GlobalModalActions } from '../global-modals/actions';

import { getShouldShowContextModal } from './selectors';

function* contextEventSaga(): SagaIterator {
  const subscription: ReturnType<typeof getSubscription> = yield select(getSubscription);

  if (!subscription) {
    yield take(SubscriptionActionNames.SUBSCRIPTION_FETCHED);
  }

  const hasFetchedAgentCustomProperties = yield select(getHasFetchedAgentCustomProperties);

  if (!hasFetchedAgentCustomProperties) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    yield take(AGENT_CUSTOM_PROPERTIES.FETCH_AGENT_CUSTOM_PROPERTIES[RequestAction.SUCCESS]);
  }

  const shouldShowModal = yield select(getShouldShowContextModal);
  if (!shouldShowModal) {
    return;
  }

  if (shouldShowModal) {
    yield put(GlobalModalActions.showModal(GlobalModal.ContextModal));
  }
}

export function* contextEventModalSagas(): SagaIterator {
  yield takeEvery('APP_READY', contextEventSaga);
}
